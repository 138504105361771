<template>
  <div class="body fixed mobile">
    <section class="header profile_edit">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="$router.push('/profile')">
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
        </a>
      </div>
      <span class="fL nowrap">YLID 驗證</span>
      <div class="flexH width right"></div>
    </section>
    <div class="main">
      <div v-if="isValidated" class="icon-wrapper">
        <img src="@/assets/img/success-icon.svg" />
        <span class="text-lg cNeutral900">已完成身分驗證</span>
      </div>
      <ValidationObserver ref="observer">
        <form @submit.prevent="handleSubmit">
          <div class="mb-4 px-2">
            <ValidationProvider name="身分證字號" rules="required|nationalIdNumber" v-slot="{ errors }">
              <label for="nationalIdNumber" class="text-lg cNeutral900 font-medium">輸入身分證字號</label>
              <input v-bind:readonly="isValidated" id="nationalIdNumber"
                class="ylid-input rounded px-6 text-base pcNeutra300"
                :class="isValidated ? 'readonly-bg' : 'solid border-c01'" v-model="formData.nationalIdNumber"
                v-mask="'A#########'" type="text" placeholder="輸入身分證字號" />
              <div v-if="!isValidated" class="mt-1 text-right text-sm error">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
          <div class="mb-4 px-2">
            <ValidationProvider name="生日" rules="required" v-slot="{ errors }">
              <label for="birthday" class="text-lg cNeutral900 font-medium">生日</label>
              <input v-bind:readonly="isValidated" id="birthday" class="ylid-input rounded px-6 text-base pcNeutra300"
                :class="isValidated ? 'readonly-bg' : 'solid border-c01'" v-model="formData.birthday" type="date" />
              <div v-if="!isValidated" class="mt-1 mb-4px text-right text-sm error">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
          <div class="mb-4 px-2">
            <ValidationProvider name="電信商" rules="required" v-slot="{ errors }">
              <label for="telecom" class="text-lg cNeutral900 font-medium">電信商</label>
              <div class="ylid-dropdown">
                <select v-bind:readonly="isValidated" name="type" id="telecom"
                  class="ylid-input rounded px-6 text-base pcNeutra300"
                  :class="isValidated ? 'readonly-bg' : 'solid border-c01'" v-model="formData.telecom">
                  <option v-for="option in telecomOptions" :key="option.value" :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div v-if="!isValidated" class="mt-1 text-right text-sm error">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
          <div class="mb-4 px-2">
            <ValidationProvider name="手機號碼" rules="required|phone" v-slot="{ errors }">
              <label for="phone" class="text-lg cNeutral900 font-medium">手機號碼</label>
              <input v-bind:readonly="isValidated" id="phone" class="ylid-input rounded px-6 text-base pcNeutra300"
                :class="isValidated ? 'readonly-bg' : 'solid border-c01'" v-model="formData.phone" type="text"
                v-mask="'09########'" placeholder="輸入手機號碼" />
              <div v-if="!isValidated" class="mt-1 text-right text-sm error">{{ errors[0] }}</div>
              <div v-if="!isValidated" class="pl-4 mt-2">
                <div class="mb-4px text-c01 font-medium highlighted-text">請務必確認身分證字號及生日正確性</div>
                <div class="mb-4px text-c01 text-sm">認證過程中請關閉WiFi，請務必確認網路連線為4G或5G行動網路。</div>
                <div class="cNeutral900 text-sm ">行動電話認證限本國籍人士使用，本人申辦之月租型門號及個人行動網路(3G/4G)，如您使用預付卡、公司門號或非本人申辦門號，將無法成功驗證。
                </div>
              </div>
            </ValidationProvider>
          </div>
          <template v-if="!isValidated">
            <div class="mb-4">
              <span for="role" class="px-2 text-lg cNeutral900 font-medium">使用者同意條款</span>
              <div class="text-sm mt-2 p-1 cNeutral500 shadow-1 rounded-20px">
                臺灣網路認證股份有限公司（以下簡稱本公司）所提供的利用行動電話號碼辦理身分驗證服務（以下簡稱本服務）是透過電信公司（中華電信股份有限公司、台灣大哥大股份有限公司、遠傳電信股份有限公司）進行本服務，當您點選同意或使用本服務後，即表示您同意並遵守以下條款：
                壹、本服務依功能不同可區分為下列情形：
                一、 行動身分識別（以下簡稱服務一）係指由您發動查詢以確認您為進行交易所提供之行動電話號碼（下稱門號）及身分證字號資料是否屬實之交易。
                二、 裝置確認（以下簡稱服務二）係指由您發動確認您為進行交易所使用之門號是否為您所使用。
                三、 門號資料核實（以下簡稱服務三）係指由您同意的服務
              </div>
            </div>
            <div class="mb-4">
              <div class="rows">
                <input v-model="formData.isChecked" class="checkbox-size" type="checkbox" id="option1" name="options"
                  value="false" />
                <div class="ml-8 gray text-base">
                  <label for="option1">我已閱讀並同意使用者條款</label>
                </div>
              </div>
            </div>
            <button type="submit" class="button rounded-20px text-lg fixed-bottom" :disabled="!formData.isChecked">
              執行身分驗證
            </button>
          </template>
        </form>
      </ValidationObserver>
      <ConfirmDialog ref="dialogRef" :type="dialogInfo.type" :title="dialogInfo.title" :content="dialogInfo.content"
        :buttons="dialogInfo.buttons" />
      <div v-if="isValidated" class="mt-8 full-width text-center text-c01 font-medium">
        ＊驗證完成！此ID不得重複驗證，以免影響權益
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import ConfirmDialog from "@/components/Dialog/ConfirmDialog.vue";

const DIALOG_TYPE_SUCCESS = 'success'
const DIALOG_TYPE_FAILED = 'failed'
const DIALOG_TYPE_INFO = 'info'

extend('phone', {
  message: '手機號碼必須為 10 個字元',
  validate(value) {
    return value.length === 10;
  },
});

extend('nationalIdNumber', {
  message: '身分證字號必須為 10 個字元',
  validate(value) {
    return value.length === 10;
  },
});

export default {
  name: "VLIDValidation",
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmDialog,
  },
  data() {
    return {
      connectionType: "未知",
      isValidated: false,
      dialogType: '',
      formData: {
        telecom: '',
        phone: '',
        nationalIdNumber: '',
        birthday: '',
        isChecked: false,
      },
      failedTitle: '驗證失敗',
      failedContent: null,
      telecomOptions: [
        { value: "46601", label: "遠傳電信" },
        { value: "46697", label: "台灣大哥大" },
        { value: "46692", label: "中華電信" }
      ]
    };
  },
  computed: {
    dialogInfo() {
      switch (this.dialogType) {
        case DIALOG_TYPE_INFO:
          return {
            type: this.dialogType,
            title: "請確認您關閉WiFi且已開啟個人行動網路！",
            buttons: [
              {
                text: "再試一次",
                click: ({ close }) => {
                  close();
                },
              },
            ],
          }
        case DIALOG_TYPE_SUCCESS:
          return {
            type: this.dialogType,
            title: "驗證成功",
            buttons: [
              {
                text: "OK",
                click: ({ close }) => {
                  this.setStoredTwidToForm();
                  close();
                },
              },
            ],
          }
        case DIALOG_TYPE_FAILED:
          return {
            type: this.dialogType,
            title: this.failedTitle,
            content: this.failedContent,
            buttons: [
              {
                text: "再試一次",
                click: ({ close }) => {
                  close();
                  this.resetForm();
                },
              },
            ],
          }
        default:
          return {
            type: 'unknown',
            title: "未知錯誤！",
            buttons: [
              {
                text: "關閉",
                click: ({ close }) => {
                  close();
                },
              },
            ],
          };
      }
    }
  },
  methods: {
    checkConnectionType() {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection && connection.type) {
        this.connectionType = connection.type === "cellular" ? "行動網路" : connection.type;
      } else if (connection && connection.effectiveType) {
        if (connection.effectiveType === "4g" || connection.effectiveType === "3g" || connection.effectiveType === "2g") {
          this.connectionType = "行動網路";
        } else if (connection.effectiveType === "wifi") {
          this.connectionType = "Wi-Fi";
        } else {
          this.connectionType = connection.effectiveType;
        }
      } else {
        this.connectionType = "無法檢測";
      }
    },
    loadTwidSdk() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        if (process.env.VUE_APP_ENV === "production") {
          script.src = "https://twid.ddpay.ai/sdk/twid.js";
        } else if (process.env.VUE_APP_ENV === "dev") {
          script.src = "https://dev.twid.ddpay.ai/sdk/twid.js";
        }
        script.onload = () => {
          console.log("TWID SDK loaded successfully");
          resolve();
        };
        script.onerror = () => {
          console.error("Failed to load TWID SDK");
          reject(new Error("TWID SDK 載入失敗"));
        };
        document.head.appendChild(script);
      });
    },
    async handleSubmit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      if (this.connectionType !== '行動網路') {
        this.dialogType = DIALOG_TYPE_INFO;
        this.$refs.dialogRef.open();
        return;
      }
      try {
        await this.loadTwidSdk();
        const sdk = new TwidSDK({
          appId: 'test-app-id'
        });
        sdk.init();
        const data = {
          phone: this.formData.phone,
          id: this.formData.nationalIdNumber,
          birthday: this.formData.birthday,
          telecom: this.formData.telecom,
        };
        const result = await sdk.verify(data);
        console.log('result=', result)
        if (result.data?.ResultCode === 'F') {
          this.failedTitle = `驗證失敗(${result.data?.ReturnCode})，${result.data?.ReturnCodeDesc}`;
          this.dialogType = DIALOG_TYPE_FAILED;
        } else if (result.success) {
          const twidData = {
            phone: this.formData.phone,
            nationalIdNumber: this.formData.nationalIdNumber,
            birthday: this.formData.birthday,
            telecom: this.formData.telecom,
          };
          localStorage.setItem('twidData', JSON.stringify(twidData));
          this.dialogType = DIALOG_TYPE_SUCCESS;
        } else if (!result.success) {
          this.failedTitle = `驗證失敗(${result.code})，${result.error.message}`;
          this.dialogType = DIALOG_TYPE_FAILED;
        }
      } catch (error) {
        console.error("載入或使用 TWID SDK 時出錯：", error);
      } finally {
        this.$refs.dialogRef.open();
      }
    },
    setStoredTwidToForm() {
      const storedData = localStorage.getItem('twidData');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        this.formData.phone = parsedData.phone;
        this.formData.nationalIdNumber = parsedData.nationalIdNumber;
        this.formData.birthday = parsedData.birthday;
        this.formData.telecom = parsedData.telecom;
        this.isValidated = true;
      }
    },
    resetForm() {
      this.formData.phone = '';
      this.formData.nationalIdNumber = '';
      this.formData.birthday = '';
      this.formData.telecom = '';
    }
  },
  mounted() {
    this.checkConnectionType();
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection && connection.addEventListener) {
      connection.addEventListener("change", this.checkConnectionType);
    }
    this.setStoredTwidToForm();
  },
  beforeDestroy() {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection && connection.removeEventListener) {
      connection.removeEventListener("change", this.checkConnectionType);
    }
  },
}
</script>
<style scoped lang="scss">
button {
  border: 0;
  color: white;
  background-color: var(--c01);

  &:disabled {
    background: #bdbdbd;
  }

  &.cancel {
    color: black;
    border: 1px solid black;
  }
}

.popup {
  display: flex !important;
}

.disabled {
  opacity: 1 !important;
  background-color: var(--cOther1) !important;
}

.rows {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ml-4 {
  margin-left: 0.25rem;
}

.ml-8 {
  margin-left: 0.5rem;
}

.full-width {
  width: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ylid-input {
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0 0 0;

  height: 40px;
  min-height: 40px;
}

.fixed-bottom {
  box-sizing: border-box;
  position: fixed;
  bottom: 1rem;
}

.checkbox-size {
  width: 1rem;
  height: 1rem;
}

.highlighted-text::before {
  content: "*";
  margin-right: 0.3125rem;
  font-weight: bold;
  position: absolute;
  left: 1.5625rem;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
}

.readonly-bg {
  background-color: #f4f4f4;
}

.ylid-dropdown {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--c01);
    pointer-events: none;
  }
}
</style>