<template>
  <div class="body fixed mobile">
    <section class="header profile_edit">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="$router.push('/profile')">
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
        </a>
      </div>
      <span class="fL nowrap">綁定悠遊卡</span>
      <div class="flexH width right"></div>
    </section>
    <div class="main">
      <template v-if="!isBound">
        <ValidationObserver ref="observer">
          <form @submit.prevent="handleSubmit">
            <div class="mb-4 mt-4 px-2">
              <div class="rows">
                <div class="text-lg cNeutral900 font-medium">綁定悠遊卡</div>
                <div
                  class="rows ml-4"
                  @click="openHint()"
                >
                  <img src="@/assets/icon/uide/information.svg" alt="資訊圖示" />
                  <span class="ml-4 cNeutral900 text-sm ">為何要綁定悠遊卡</span>
                </div>
              </div>
            </div>
            <div class="mb-4 px-2">
              <ValidationProvider
                name="悠遊卡卡號"
                rules="required|cardNumber"
                v-slot="{ errors }"
              >
              <label for="cardNumber" class="text-lg cNeutral900 font-medium">輸入悠遊卡卡號</label>
              <input
                class="ezcard-input rounded solid border-c01 px-6 text-base pcNeutra300 card-number"
                type="text"
                v-mask="'####-####-####-####'"
                v-model="formData.cardNumber"
                placeholder="請輸入您的16位悠遊卡卡號"
              />
              <div class="mt-1 text-right text-sm error">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <div class="mb-4 px-2">
              <ValidationProvider
                name="身分證字號"
                rules="required|nationalId"
                v-slot="{ errors }"
              >
              <label for="nationalIdNumber" class="text-lg cNeutral900 font-medium">輸入身分證字號</label>
              <input
                id="nationalIdNumber"
                class="ezcard-input rounded solid border-c01 px-6 text-base pcNeutra300"
                v-model="formData.nationalIdNumber"
                v-mask="'A#########'"
                type="text"
                placeholder="輸入身分證字號"
              />
              <div class="mt-1 text-right text-sm error">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <div class="mb-4">
              <span class="px-2 text-lg cNeutral900 font-medium">個資使用聲明</span>
              <div class="text-sm mt-2 p-1 cNeutral500 shadow-1 rounded-20px">
                依據個人資料保護法等相關規定，
（以下簡稱）有義務告知以下事項：
利用雲林幣進行「設定悠遊卡綁定」，將提供您的「卡號」至「雲林幣系統」，其個人資料的利用之期間、對象、地區及方式，皆以該系統或該單位的「隱私權公告」為主。
本次蒐集之個人資訊，僅限「設定悠遊卡綁定」使用，並遵守個人資料保護法相關規定，保障使用者的個資。
若您是對雲林幣有任何疑問，請點擊APP內的聯絡客服，由專人為您服務。
              </div>
            </div>
            <div class="mb-4">
              <div class="rows">
                <input
                  v-model="formData.isChecked"
                  class="checkbox-size"
                  type="checkbox"
                  id="announcement"
                  name="options"
                  value="false"
                />
                <div class="ml-8 gray text-base">
                  <label for="announcement">本人已詳閱並同意以上條款個資使用聲明</label>
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="button rounded-20px text-lg fixed-bottom"
              :disabled="!formData.isChecked"
            >
              綁定
            </button>
          </form>
        </ValidationObserver>
      </template>
      <template v-else>
        <div class="mt-4 mb-4 text-sm p-1 cNeutral500 shadow-1 rounded-20px">
          <div class="rows mb-4">
            <img
              src="@/assets/icon/uide/easyCardIcon.svg"
              alt="悠遊卡圖示"
            />
            <span class="ml-4 text-base font-medium">悠遊卡 {{formData.cardNumber}}</span>
          </div>
          <img src="@/assets/icon/uide/easyCard.svg" alt="悠遊卡" class="full-width" />
        </div>
        <button type="submit" class="button rounded-20px text-lg fixed-bottom" @click="unbindEasyCard">
          解除綁定卡片
        </button>
      </template>
      <ConfirmDialog
        ref="dialogRef"
        :type="dialogInfo.type"
        :title="dialogInfo.title"
        :content="dialogInfo.content"
        :buttons="dialogInfo.buttons"
      />
    </div>
  </div>
</template>

<script>
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import ConfirmDialog from "@/components/Dialog/ConfirmDialog.vue";

extend('cardNumber', {
  message: '格式錯誤，應為 16 位數字',
  validate(value) {
    return value.length === 19;
  },
});

extend('nationalId', {
  message: '身分證字號必須為 10 個字元',
  validate(value) {
    return value.length === 10;
  },
});

export default {
  name: "EZCardBinding",
  
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmDialog,
  },
  data() {
    return {
      isBound: false,
      dialogType: '',
      formData: {
        cardNumber: '',
        nationalIdNumber: '',
        isChecked: false,
      },
      dialogTitle: '',
      dialogMessage: '',
    };
  },
  computed: {
    dialogInfo() {
      switch(this.dialogType){
        case 'info':
          return {
            type: this.dialogType,
            title: "確定要解除綁定卡片？",
            buttons: [
              {
                text: "取消",
                outline: true,
                click: ({ close }) => {
                  close();
                },
              },
              {
                text: "確定",
                click: ({ close }) => {
                  close();
                  // TODO: demo 使用，停在綁定畫面
                  this.isBound = false;
                  this.resetForm();
                  // TODO: 解除綁定 api
                },
              },
            ],
      }
        case 'success':
        return {
          type: this.dialogType,
          title: "悠遊卡綁定成功",
          buttons: [
            {
              text: "確定",
              click: ({ close }) => {
                close();
                // TODO: demo 用途，停在綁定後的顯示狀態
                this.isBound = true;
                // this.$router.push('/profile')
              },
            },
          ],
        }
        case 'hint':
        return {
          type: this.dialogType,
          title: "為何要綁定悠遊卡？",
          content: "為雲林幣實體卡證，可作為身份辨識使用。",
          buttons: [
          {
                text: "確定",
                click: ({ close }) => {
                  close();
                },
              },
          ],
        }
        default:
        return {
          type: 'unknown',
          title: "未知錯誤，請聯絡客服！",
          buttons: [
            {
              text: "關閉",
              click: ({ close }) => {
                close();
              },
            },
          ],
        }
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.observer.validate()
      .then((valid) => {
        if(valid) {
        // TODO: 送出前須要把 19 位的 number 去除連字號
        // const cardNumberWithoutHyphens = this.formData.cardNumber.replace(/-/g, '');

        // TODO: 打 api，成功後才跑下面 dialog
        this.dialogType = 'success';
        this.$refs.dialogRef.open();
        // TODO: demo 使用，讓卡號顯示
        // this.resetForm();
      }})
    },
    openHint() {
      this.dialogType = 'hint';
      this.$refs.dialogRef.open();
    },
    unbindEasyCard() {
      this.dialogType = 'info';
      this.$refs.dialogRef.open();
    },
    resetForm() {
      this.formData.cardNumber = '';
      this.formData.nationalIdNumber = '';
    }
  },
}
</script>
<style scoped lang="scss">
button {
  border: 0;
  color: white;
  background-color: var(--c01);

  &:disabled {
    background: var(--cNeutral300);
  }

  &.cancel {
    color: black;
    border: 1px solid black;
  }
}

.popup {
  display: flex !important;
}

.disabled {
  opacity: 1 !important;
  background-color: var(--cOther1) !important;
}

.rows {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ml {
  &-4 {
    margin-left: 0.25rem;
  }
  &-8 {
    margin-left: 0.5rem;
  }
}

.full-width {
  width: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-number {
  letter-spacing: 0.625rem;
  text-align: center;

  &::placeholder {
    letter-spacing: 0rem;
    text-align: left;
  }
}

.ezcard-input {
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0 0 0 ;

  height: 40px;
  min-height: 40px;
}

.fixed-bottom {
  box-sizing: border-box;
  position: fixed;
  bottom: 1rem;
}

.checkbox-size {
  width: 1rem;
  height: 1rem;
}
</style>