<template>
  <div class="dialog-wrapper" :class="isOpen ? '' : 'hide'">
    <dialog :open="isOpen" class="dialog" @click.prevent="handleDialog">
      <div class="icon-wrapper">
        <img v-if="type === 'info'" src="@/assets/img/info-icon.svg" />
        <img v-if="type === 'success'" src="@/assets/img/success-icon.svg" />
      </div>
      <h2 class="title">{{ title }}</h2>
      <div class="content">
        <p class="fM">{{ content }}</p>
      </div>
      <div class="buttons">
        <button
          v-for="(button, i) in buttons"
          :key="i"
          :class="buttonClass(button)"
          @click="() => handleClick(button)"
        >
          {{ button.text }}
        </button>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '提示',
    },
    content: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    handleDialog(e) {
      e.stopPropagation();
    },
    handleClick(button) {
      button.click({
        close: this.close
      });
    },
    buttonClass(button) {
      return [
        'fM',
        'rounded',
        button.outline ? 'button-outline' : '',
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  ol {
    margin-bottom: 0;
  }

  .title {
    text-align: center;
    font-size: clamp(1.125rem, 4.5vw, 1.35rem);
    font-weight: 500;
  }

  .content {
    text-align: center;
    margin-top: 8px;
  }

  .buttons {
    margin-top: 20px;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;

    img {
      margin-bottom: 10px;
    }
  }
}
</style>